import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeaderSimple from "../components/HeaderSimple";

import logoGruppo from "../static/logos/logo-gruppo.png";

import "../utils/general.scss";

const NewsSingle = ({ pageContext }) => {
    let [details, setDetails] = useState(null);
    let [shownContent, setShownContent] = useState("");
    let [shownTitle, setShownTitle] = useState("");

    useEffect(() => {
        setDetails(JSON.parse(pageContext.content));
    }, [pageContext]);

    useEffect(() => {
        if (typeof window !== 'undefined' && details) {
            // set eng version or italian for content and title
            if (window.localStorage.language === "en" && details.metadatas) {
                setShownContent(details.metadatas.content_eng || details.content);
                setShownTitle(details.metadatas.name_eng || details.name);
            } else {
                setShownContent(details.content);
                setShownTitle(details.name);
            }
        }
    }, [details]);
 
    return (
        <Layout pageContext={pageContext}>
            {details && 
                <>
                <SEO title={`${shownTitle} | Comunicati`}>
                    {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
                </SEO>

                <HeaderSimple subtitle={`${new Date(details.publishedAt.date.split(' ')[0]).getDate()}/${new Date(details.publishedAt.date.split(' ')[0]).getMonth() + 1}/${new Date(details.publishedAt.date.split(' ')[0]).getFullYear()}`} title={shownTitle} />
                {details.image &&
                    <div className="newsImage">
                        <img 
                            src={details.banner ? details.banner : details.image}
                        />
                    </div>
                }

                <p 
                    style={{width: "80%", margin: "0 auto"}}
                    dangerouslySetInnerHTML={{__html: shownContent}}
                ></p>
                </>
            }
        </Layout>
    )
}

export default NewsSingle
